import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createUseStyles, useTheme } from "react-jss";
import { httpFetchRookie } from "../../httpRequests/rookie.http";
import Back from "../../components/Back";
import {
  computeAge,
  getAppLink,
  redirectToExternalLink,
  remapURLSearchParams,
} from "../../utilities/utils";
import RookiePhotoSlider from "./RookiePhotoSlider/RookiePhotoSlider";
import RookieCardContent from "./RookieCardContent/RookieCardContent";
import Spinner from "../../components/Spinner";
import RookieCTABar from "./RookieCTABar/RookieCTABar";
import {
  APP_ROUTE_SIGNUP_LEADER,
  APP_ROUTE_SIGNUP_LEADER_SOCIAL,
} from "../../utilities/constants/appRouteNames";
import {
  PARAM_ROOKIE_PROFILE,
  PARAM_SHARE,
  PARAM_USER_GROUP,
} from "../../utilities/constants";
import { getUserGroup, USER_GROUPS } from "../../utilities/constants/user";
import CTAModal from "../../components/CTAModal";
import Animation from "../../components/Animation";
import alert from "../../assets/animations/Alert-1.json";
import { Trans, useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../translations";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Context from "../../Context";
import { useDispatch, useSelector } from "react-redux";
import { selectThemeMode } from "../../store/settingSlice";
import Hidden from "../../components/Hidden";
import ShareModal from "../../components/ShareModal";
import SendingMessageModal from "../sendMessageModal/SendingMessageModal";
import { selectPersona, selectPersonaDefault } from "../../store/userSlice";
import {
  setCardIndex,
  setLeaderCardIndex,
  setRefreshCarousel,
} from "../../store/carouselSlice";
import { httpGetTranslation } from "../../httpRequests/translation.http";
import i18n from "../../i18n";

const useStyles = createUseStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "grid",
    gridTemplateRows: "1fr",
    padding: [32, 16],
    /*[theme.lUp]: {
            padding: 0
        },*/
  },
  content: {
    display: "grid",
    position: "relative",
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    margin: "0 auto",
    pointerEvents: "auto",
    gridRowGap: 20,
    [theme.lUp]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      marginTop: 32,
      paddingBottom: "75px",
      columnGap: "60px",
    },
  },
  titleWrapper: {
    display: "grid",
    gridTemplateRows: "auto auto",
    gridTemplateColumns: "50px 1fr 50px",
    columnGap: 8,
    padding: [0, 0, 32, 0],
    [theme.l]: {
      padding: [0, 0, 16, 0],
      alignItems: "center",
      "& h1": {
        fontSize: 20,
        margin: 0,
        [theme.m]: {
          fontSize: 24,
        },
      },
    },
    [theme.lUp]: {
      padding: 0,
    },
  },
  title: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridRowStart: 2,
    justifyContent: "center",
    gridColumnGap: 16,
    alignItems: "center",
    "& h1": {
      ...theme.pageStyle.title,
      textAlign: "center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    [theme.lUp]: {
      gridTemplateColumns: "auto 1fr 11px",
      fontSize: "32px",
      order: "2",
      height: "fit-content",
      "& h1": {
        textAlign: "left",
      },
    },
  },
  rookieCardOnlineIndicator: {
    width: 11,
    height: 11,
    borderRadius: "50%",
    backgroundColor: theme.colors.green,
  },
  mainTitle: {
    gridRowStart: 1,
    gridColumn: "1/-1",
    justifySelf: "center",
    fontSize: 26,
    fontWeight: 700,
    letterSpacing: "-0.05em",
    textAlign: "center",
    color: ({ themeMode }) =>
      themeMode === "dark" ? theme.colors.white : theme.colors.darkPurple,
    marginTop: 0,
    whiteSpace: "pre-wrap",
    "& div": {
      ...theme.textGradients.primary,
    },
    [theme.sUp]: {
      fontSize: 20,
    },
    [theme.mUp]: {
      fontSize: 32,
    },
    [theme.lUp]: {
      gridRowStart: 1,
    },
    [theme.xlUp]: {
      fontSize: 48,
    },
  },
  backButton: {
    gridRowStart: 2,
    alignItems: "center",
    cursor: "pointer",
  },
  backButtonTop: {
    display: "none",
    marginBottom: "30px",
    [theme.lUp]: {
      display: "block",
    },
  },
  rookieInfo: {
    order: 2,
    display: "grid",
    gridTemplateRows: "auto auto auto",
    justifyContent: "center",
    alignItems: "center",
  },
  rookiePhotoSlider: {
    justifySelf: "center",
    position: "relative",
    [theme.xsUp]: {
      maxWidth: 340,
    },
    [theme.sUp]: {
      maxWidth: 360,
    },
    [theme.mUp]: {
      maxWidth: 400,
    },
    [theme.lUp]: {
      order: "1",
    },
  },
  rookieCardContent: {
    [theme.lUp]: {
      order: "4",
      //marginTop: "-425px"
    },
    [theme.xlUp]: {
      order: "4",
      //marginTop: "-300px"
    },
  },
  rookieCTABar: {
    whiteSpace: "nowrap",
    marginTop: "-48px",
    alignItems: "center",
    maxWidth: "296px",
    zIndex: "1",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.lUp]: {
      order: "3",
      marginTop: "-70px",
    },
  },
  alertAnimation: {
    margin: "0 auto",
    height: "220px",
    width: "240px",
    padding: "15px",
    [theme.mUp]: {
      padding: 0,
    },
  },
  rookieCardDescriptionWrapper: {
    position: "absolute",
    alignItems: "center",
    maxWidth: "296px",
    bottom: "0",
    zIndex: "1",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "64px",
    [theme.mUp]: {
      top: "300px",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: -13,
      left: "calc(50% - 7.5px)",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "0 7.5px 14px 7.5px",
      borderColor: "transparent transparent #ffffff transparent",
    },
  },
  photoSlider: {
    display: "grid",
  },
  rookieCardDescription: {
    display: "-webkit-box",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: theme.colors.white,
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: "-0.05em",
    color: theme.colors.darkPurple,
    padding: [theme.spacing * 1.5, theme.spacing * 3],
    margin: [0, theme.spacing * 3, theme.spacing * 3, theme.spacing * 3],
    borderRadius: 10,
    boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
    textAlign: "center",
    position: "relative",
    maxHeight: "108px",
    borderBottom: "5px solid white",
    "& span": {
      color: theme.colors.darkPurple,
    },
    [theme.xxsUp]: {
      fontSize: 14,
    },
    [theme.mUp]: {
      maxHeight: "128px",
      maxWidth: "80%",
      padding: [theme.spacing * 2, theme.spacing * 3],
      fontSize: 18,
      lineHeight: 1.3,
    },
    [theme.lUp]: {
      fontSize: 20,
      margin: [0, theme.spacing * 3, theme.spacing * 8, theme.spacing * 3],
    },
  },

  textOverlay: {
    position: "absolute",
    top: "40%",
    left: "34px",
    fontWeight: 700,
    fontSize: 20,
    color: "white",
    width: "275px",
    textAlign: "center",
    userSelect: "none",

    [theme.mUp]: {
      top: "15%",
      left: "15%",
    },
  },
}))

const fetchComponentData = async ({ param: rookieUserName }) => {
  if (!rookieUserName) return null;
  const rookieDataResponse = await httpFetchRookie(rookieUserName)
  const rookieData = rookieDataResponse.data
  if (!rookieData) return null

  const initialLanguage = i18n.language.split("_")[0];

  // Aggregating items to translate with a unique key
  const itemsToTranslate = [
    ...rookieData.chat_topics.map((item) => ({
      id: item.id,
      text: item.name,
      type: "chat_topic",
    })),
    ...rookieData.favorite_activities.map((item) => ({
      id: item.id,
      text: item.name,
      type: "favorite_activity",
    })),
  ]

  // Only add wishes if they are not null
  if (rookieData?.type_attributes?.path?.id && rookieData?.type_attributes?.path?.name) {
    itemsToTranslate.push({
      id: rookieData.type_attributes.path.id,
      text: rookieData.type_attributes.path.name,
      type: "wish",
    })
  }

  // Preparing contents for translation API
  const contentsToTranslate = itemsToTranslate.map((item) => ({
    id: `${item.type}_${item.id}`,
    text: item.text,
  }))

  const translationResponse = await httpGetTranslation({
    contents: contentsToTranslate,
    target: initialLanguage,
  })

  // Creating a map from the translation response
  const translatedItemsMap = new Map(
    translationResponse.data.map((item) => [item.id, item.text])
  )

  // Function to get the translated text using combined key
  const getTranslatedText = (id, type) => {
    return (
      translatedItemsMap.get(`${type}_${id}`) ||
      itemsToTranslate.find((item) => item.id === id && item.type === type).text
    )
  }

  // Distributing translations back to the appropriate fields
  const translatedChatTopics = rookieData?.chat_topics?.map((topic) => ({
    ...topic,
    name: getTranslatedText(topic?.id, "chat_topic"),
  }))

  const translatedFavActivities = rookieData?.favorite_activities?.map(
    (activity) => ({
      ...activity,
      name: getTranslatedText(activity?.id, "favorite_activity"),
    })
  )

  const translatedWishes = rookieData?.type_attributes?.path?.id ? {
    ...rookieData?.type_attributes?.path,
    name: getTranslatedText(rookieData?.type_attributes?.path?.id, "wish"),
  } : null

  // Merging translated data back into rookieData
  const rookieDataWithTranslation = {
    ...rookieData,
    chat_topics: translatedChatTopics,
    favorite_activities: translatedFavActivities,
    type_attributes: {
      ...rookieData?.type_attributes,
      path: translatedWishes,
    },
  }

  return rookieDataWithTranslation
}

const RookieProfile = (props) => {
  const themeMode = useSelector(selectThemeMode);
  const classes = useStyles({ themeMode });
  const context = useContext(Context);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
  const history = useHistory();
  const [rookie, setRookie] = useState(context?.componentData);
  const [isLoading, setIsLoading] = useState(true);
  const [showShareModal, setShowShareModal] = useState(false);
  const [isSendingMessageOpen, setIsSendingMessageOpen] = useState(false);
  const { username: rookieUserName } = useParams();
  const parsedUrl =
    typeof window !== "undefined" ? new URL(window.location.href) : "";
  const setPersonaAsDefault = useSelector(selectPersonaDefault);
  const userGroup = getUserGroup();
  const personaId = useSelector(selectPersona);
  const { search, state } = useLocation();
  const queryString = useMemo(() => new URLSearchParams(search), [search]);
  const lang = i18n.language.split("_")[0];

  useEffect(() => {
    if (state?.rookieCardIndex > -1) {
      dispatch(setCardIndex(state?.rookieCardIndex));
      dispatch(setLeaderCardIndex(state?.leaderCardIndex));
      dispatch(setRefreshCarousel(false));
    }

    if (
      rookieUserName !== rookie?.username ||
      !rookie ||
      (rookie && !Object.values(rookie).length)
    ) {
      fetchRookie();
    } else {
      setIsLoading(false);
    }
  }, [rookieUserName, rookie, state]);

  useEffect(() => {
      fetchRookie();
  }, [lang]);

  const age = computeAge(rookie?.type_attributes?.birth_date);

  const rookieGender = useMemo(() => {
    switch (rookie?.type_attributes?.gender?.id) {
      case 1:
        return "male";
      case 2:
        return "female";
      default:
        return "other";
    }
  }, [rookie]);

  const [isOpenRookieNotFound, setIsOpenRookieNotFound] = useState(false);

  const fetchRookie = async () => {
    try {
      setIsLoading(true);
      const data = await fetchComponentData({ param: rookieUserName });
      setRookie(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onGiftHandler = useCallback(() => {
    redirectToExternalLink(
      getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
        ...remapURLSearchParams(search),
        {
          name: PARAM_ROOKIE_PROFILE,
          value: rookie?.username,
        },
        {
          name: PARAM_USER_GROUP,
          value: getUserGroup(),
        },
      ])
    );
  }, [search, rookie]);

  const getRandomCTA = useCallback(
    (rookieName = "") => {
      let experimentalRookieCardCTA;
      switch (userGroup) {
        case USER_GROUPS.a:
          experimentalRookieCardCTA = `home_carousel.discuss_now_with_rookie_variant_A`;
          break;
        case USER_GROUPS.b:
          experimentalRookieCardCTA = `home_carousel.discuss_now_with_rookie_variant_B`;
          break;
        default:
          experimentalRookieCardCTA = `home_carousel.discuss_now_with_rookie_variant_C`;
          break;
      }
      // Setup persona
      const rookieCardPersonaCTA = [
        `rookie_card_personas.${personaId}.${state?.rookieCardIndex ?? 0}.CTA`,
        `rookie_card_personas.${0}.${state?.rookieCardIndex ?? 0}.CTA`,
      ];

      const rookieCardCTA =
        !!personaId || setPersonaAsDefault
          ? rookieCardPersonaCTA
          : experimentalRookieCardCTA;
      return (
        <Trans t={t} i18nKey={rookieCardCTA} values={{ USER: rookieName }} />
      );
    },
    [t, personaId, userGroup]
  );

  return (
    <>
      <Helmet>
        {rookie?.photos?.length && (
          <meta property="og:image" content={`${rookie.photos[0].url}`} />
        )}
        {rookie?.full_name && (
          <meta
            property="og:title"
            content={`${rookie?.full_name} - Morgis.com`}
          />
        )}
      </Helmet>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={classes.root}>
          <div className={classes.titleWrapper}>
            <div className={classes.backButton}>
              {!queryString.get(PARAM_SHARE) && (
                <Back callback={() => history.goBack()} />
              )}
            </div>
            <Hidden up={theme.breakPoints.l}>
              <div className={classes.title}>
                {rookie?.is_online && (
                  <div className={classes.rookieCardOnlineIndicator} />
                )}
                <h1>{`${rookie?.full_name}, ${age}`}</h1>
              </div>
            </Hidden>
            <Hidden up={theme.breakPoints.m}>
              {queryString.get(PARAM_SHARE) && (
                <h1 className={classes.mainTitle}>
                  <Trans
                    t={t}
                    i18nKey={"home_carousel.rookie_profile_share_title"}
                    components={{ div: <div /> }}
                  />
                </h1>
              )}
            </Hidden>
          </div>
          <div className={classes.content}>
            <div className={classes.rookiePhotoSlider}>
              <div className={classes.rookieCardDescriptionWrapper}>
                <span className={classes.rookieCardDescription}>
                  <Trans
                    t={t}
                    i18nKey="home_carousel.rookie_help_message"
                    components={{ span: <span /> }}
                    values={{
                      PATH:
                        !!rookie?.chat_topics.length &&
                        rookie?.chat_topics
                          .map((topic) => topic?.name)
                          .reduce((acc, name, index, arr) => {
                            if (index === 0) {
                              return name;
                            } else if (index === arr.length - 1) {
                              return `${acc} ${t("home_carousel.and")} ${name}`;
                            } else {
                              return `${acc}, ${name}`;
                            }
                          }, ""),
                    }}
                  />
                </span>
              </div>
              <RookiePhotoSlider
                photos={rookie?.type_attributes?.photos}
                photosCount={rookie?.type_attributes?.photos_count}
                rookieUsername={rookie?.full_name}
                redirectAction={onGiftHandler}
              />
              <div className={classes.rookieCTABar}>
                <RookieCTABar
                  onGift={onGiftHandler}
                  fontWeight={500}
                  onStartChat={() => setIsSendingMessageOpen(true)}
                  message={t("rookie_profile_page.sign_up")}
                />
              </div>
            </div>
            <div className={classes.rookieInfo}>
              <Hidden down={theme.breakPoints.l}>
                {queryString.get(PARAM_SHARE) && (
                  <h1 className={classes.mainTitle}>
                    <Trans
                      t={t}
                      i18nKey={"home_carousel.rookie_profile_share_title"}
                      components={{ div: <div /> }}
                    />
                  </h1>
                )}
              </Hidden>

              <div className={classes.rookieCardContent}>
                <Hidden down={theme.breakPoints.l}>
                  <div className={classes.title}>
                    {rookie?.is_online && (
                      <div className={classes.rookieCardOnlineIndicator} />
                    )}
                    <h1>
                      {`${rookie?.type_attributes?.first_name} ${rookie?.type_attributes?.last_name}, ${age}`}
                    </h1>
                  </div>
                </Hidden>
                <RookieCardContent
                  rookieProfile={rookie}
                  onShare={() => setShowShareModal(true)}
                  redirectAction={onGiftHandler}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isSendingMessageOpen && (
        <SendingMessageModal
          onClose={() => setIsSendingMessageOpen(null)}
          rookieUsername={rookie?.username}
          name={rookie?.type_attributes?.first_name}
          avatar={rookie?.avatar?.url}
          rookieGender={rookie?.type_attributes.gender?.id}
          ctaText={getRandomCTA(rookie?.type_attributes?.first_name)}
        />
      )}
      {isOpenRookieNotFound && (
        <CTAModal
          isBackButton={false}
          onClick={() => history.push("/")}
          onClose={() => history.push("/")}
          title={t("attention_modal.title")}
          description={t("attention_modal.description")}
          buttonText={t("attention_modal.go_home")}
        >
          <Animation
            animationData={alert}
            animationStyleClass={classes.alertAnimation}
          />
        </CTAModal>
      )}
      {showShareModal && (
        <ShareModal
          title={t("gift_rookie.share_with_friends")}
          onClose={() => {
            setShowShareModal(false);
          }}
          url={`${parsedUrl.origin}${parsedUrl.pathname}?${PARAM_SHARE}=true`}
          message={t("share_messages.leader_sharing", {
            context: rookieGender,
          })}
        />
      )}
    </>
  );
};

export default RookieProfile;

RookieProfile.serverFetch = fetchComponentData;